import React from "react";

import { Hero } from "../components/layout/Hero";

import { Col, Container, Row } from "reactstrap";
import { D1, H2, H5, Span } from "../components/theme/text/Headings";
import { White } from "../components/theme/colors";
import { ButtonBlack } from "../components/theme/Button";

import hero from "../img/hero.svg";
import { Section } from "../components/layout/Section";
import CourseBox from "../components/elements/CourseBox";

function Home() {
  return (
    <>
      <Hero bgcolor={White} paddingTop="10px" height="auto">
        <Container className="overflow-hidden mt-50">
          <Row>
            <Col
              lg={{ size: 4, order: 1 }}
              md={{ size: 12, order: 2 }}
              sm={{ size: 12, order: 2 }}
              xs={{ size: 12, order: 2 }}
              className="mb-0"
            >
              <D1 Black className="uppercase mt-100 mb-10">
                <Span NeonGreen>Watch Me </Span> Build Cool Stuff 🔥{" "}
              </D1>
              <H5>Watch and learn with me as I build new businesses. </H5>
              <Row className="mt-30">
                <Col>
                  <a
                    href="https://buildwithtiktok.substack.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonBlack>Join My (Free) Newsletter</ButtonBlack>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col
              lg={{ size: 8, order: 2 }}
              md={{ size: 12, order: 1 }}
              sm={{ size: 12, order: 1 }}
              xs={{ size: 12, order: 1 }}
              className="mb-n-50"
            >
              <img
                src={hero}
                width="100%"
                style={{ minWidth: "300px" }}
                alt="girl on computer"
              />
            </Col>
          </Row>
        </Container>
      </Hero>
      <Section paddingBottom="150px">
        <Container>
          <Row>
            <Col>
              <H2>The Portfolio</H2>
            </Col>
          </Row>

          <Row className="mt-30">
            <Col lg="3" md="6" sm="12">
              <iframe
                title="home"
                src="https://buildwithtiktok.substack.com/embed"
                width="auto"
                height="320"
                style={{ border: "1px solid #EEE", background: "white" }}
                frameborder="0"
              ></iframe>
            </Col>
            <Col lg="3" md="6" sm="12">
              <CourseBox
                upcoming
                CourseLink={""}
                expertImg={hero}
                price={"10% Fee"}
                title={"Paywall My Link"}
                about={
                  "Paywall any link you have! Use it before Google Docs, AirTable databases, and other unique links."
                }
              />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default Home;
