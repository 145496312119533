import React from "react";

import { Hero } from "../components/layout/Hero";

import { Col, Container, Row } from "reactstrap";
import { D1, H4, H5, Paragraph, Span } from "../components/theme/text/Headings";
import { Black } from "../components/theme/colors";
import {
  ButtonGreen,
  ButtonNeonGreen,
  ButtonPurple,
} from "../components/theme/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { ButtonBlue } from "../theme/Button";

function Resources() {
  return (
    <>
      <Hero paddingTop="60px" paddingBottom="80px">
        <Container className="text-align-center">
          <Row>
            <D1 Black>Hi! I'm Mehak 👋🏼</D1>
            <Paragraph LightGray2 className="mb-20">
              Follow along as I build cool things! 🚀
            </Paragraph>

            <Col lg={{ size: 4, offset: 4 }} className="mb-50">
              <div className="mt-50 mb-20">
                <a
                  href="http://ow.ly/Jryb50OzPyf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ButtonBlue>
                    <Span Bold>
                      Checkout PaywallMyLink.com and <br />
                      join our waitlist!!
                    </Span>
                  </ButtonBlue>
                </a>
              </div>

              <H4 color={Black} className="mt-50 mb-20">
                My Links 👇
              </H4>

              <div className="mb-20">
                <a
                  href="https://buildwithtiktok.substack.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ButtonNeonGreen>
                    Join my FREE newsletter!{" "}
                    <Span Bold>
                      This is for builders and tinkerers that want build their
                      own businesses.
                    </Span>
                  </ButtonNeonGreen>
                </a>
              </div>

              <div className="mb-20">
                <Link to="/">
                  <ButtonPurple>
                    The Portfolio --{" "}
                    <Span Bold>
                      Want to see what I'm currently building? Check it out!
                    </Span>
                  </ButtonPurple>
                </Link>
              </div>

              <div className="mb-20">
                <a
                  href="https://www.joinskillbank.com/chatgpt-for-marketers/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ButtonGreen>ChatGPT for Marketers Course ($99)</ButtonGreen>
                </a>
              </div>
            </Col>

            <H5 className="mt-10 mb-50">
              <a
                href="https://www.instagram.com/itsmehakvohra/"
                rel="nolink noreferrer"
                target="_blank"
                style={{ color: Black, marginRight: "30px" }}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://twitter.com/itsmehakvohra"
                rel="nolink noreferrer"
                target="_blank"
                style={{ color: Black, marginRight: "30px" }}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://www.tiktok.com/@itsmehakvohra"
                rel="nolink noreferrer"
                target="_blank"
                style={{ color: Black, marginRight: "30px" }}
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
              <a
                href="https://www.linkedin.com/in/mehakvohra/"
                rel="nolink noreferrer"
                target="_blank"
                style={{ color: Black, marginRight: "30px" }}
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </H5>

            <iframe
              title="resources"
              src="https://buildwithtiktok.substack.com/embed"
              width="auto"
              height="320"
              style={{ border: "1px solid #EEE", background: "white" }}
              frameborder="0"
            ></iframe>
          </Row>
        </Container>
      </Hero>
    </>
  );
}

export default Resources;
