export const Yellow = "#f1ff53";
export const Green = "#cef95e";
export const NeonGreen = "#00e487";
export const Orange = "#f4b870";
export const Purple = "#baabe3";
export const Gray = "#565656";
export const LightGray = "#B6B6B6";
export const White = "#fff";
export const Black = "#27282a";

export const Black2 = "#2F3032";
export const Black03 = "rgba(0, 0, 0, 0.3)";
export const Gray2 = "#58667E";
export const LightGray2 = "#E2E2E3";

export const White01 = "rgba(255, 255, 255, 0.1)";
export const White03 = "rgba(255, 255, 255, 0.3)";
export const White07 = "rgba(255, 255, 255, 0.7)";

export const Yellow03 = "rgba(241, 255, 83, 0.3)";
export const Yellow07 = "#b5bf49";

export const Green01 = "rgba(206, 249, 94, 0.1)";
export const Green02 = "rgba(206, 249, 94, 0.2)";
export const Green03 = "rgba(206, 249, 94, 0.3)";
export const Green09 = "rgba(206, 249, 94, 0.9)";

export const NeonGreen01 = "rgba(0, 228, 135, 0.)";
export const NeonGreen03 = "rgba(0, 228, 135, 0.3)";
export const NeonGreen07 = "rgba(0, 228, 135, 0.7)";

export const Orange01 = "rgba(244, 184, 112, 0.1)";
export const Orange02 = "rgba(244, 184, 112, 0.2)";
export const Orange03 = "rgba(244, 184, 112, 0.3)";
export const Orange07 = "rgba(244, 184, 112, 0.7)";
export const Orange09 = "rgba(244, 184, 112, 0.9)";
export const Orange07Hex = "#F8CE9B";
export const Orange03Hex = "#FCE9D4";

export const Purple03 = "rgba(186, 171, 227, 0.3)";
export const Purple09 = "rgba(186, 171, 227, 0.9)";
