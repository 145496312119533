import * as React from "react";
import { Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../theme/styling.css";

import NavTop from "../navigation/NavTop";

const Layout = () => {
  return (
    <div style={{ maxWidth: "100%", overflowX: "hidden" }}>
      <NavTop />
      <Outlet />
    </div>
  );
};

export default Layout;
