import styled from "styled-components";

export const Hero = styled.section`
  min-height: 30vh;
  min-height: ${(props) => props.minHeight};
  height: ${(props) => props.height};
  padding: 40px 30px 75px;
  position: relative;
  border: none;
  background: ${(props) => props.bgcolor};
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  overflow: hidden;
`;

export const HeroButtonWrap = styled.div`
  margin-top: 40px;
`;
