import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
} from "reactstrap";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { ButtonBlack } from "../theme/Button";

import { Black, White } from "../theme/colors";
import { H5, Span } from "../theme/text/Headings";

const AStyled = styled.a`
  text-decoration: none;
  color: ${Black};
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${Black};
`;

const Navigation = styled.div`
  background-color: ${White};
`;

const NavContainer = styled(Container)`
  padding: 30px 0;
  position: relative;
`;

export default class NavTops extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      isCalendarOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  _handleClickNav() {
    window.analytics.track("Nav Newsletter Fired");
  }

  render() {
    return (
      <Navigation>
        <NavContainer className="hd-sm">
          <Navbar className="ml-auto" expand="lg">
            <NavbarBrand href="/" className="nav-logo black uppercase ml-25">
              <H5>Build with Mehak 🔥</H5>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <FontAwesomeIcon icon={faBars} className={"black"} />{" "}
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto navbar-inner nav-center" navbar>
                <NavItem>
                  <LinkStyled to="/resources" className="nav-link">
                    Resources
                  </LinkStyled>
                </NavItem>
                <NavItem>
                  <AStyled
                    href="https://themehakvohra.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="nav-link"
                  >
                    About Me
                  </AStyled>
                </NavItem>
                <NavItem>
                  <AStyled
                    href="https://buildwithtiktok.substack.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="nav-link"
                  >
                    Newsletter
                  </AStyled>
                </NavItem>
                <NavItem>
                  <AStyled
                    href="https://www.joinskillbank.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="nav-link"
                  >
                    Courses
                  </AStyled>
                </NavItem>
              </Nav>
              <div className="nav-right">
                <AStyled
                  href="https://www.joinskillbank.com/chatgpt-for-marketers/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                >
                  <ButtonBlack>
                    Learn From Me <Span className="ml-5">🤫</Span>
                  </ButtonBlack>
                </AStyled>
              </div>
            </Collapse>
          </Navbar>
        </NavContainer>
      </Navigation>
    );
  }
}
