import styled from "styled-components";
import {
  Black,
  Green,
  Green09,
  NeonGreen,
  NeonGreen07,
  Orange,
  Orange09,
  Purple,
  Purple09,
  White,
  Yellow,
  Yellow07,
} from "./colors";

export const ButtonYellow = styled.button`
  background-color: ${Yellow};
  border: none;
  color: ${Black};
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: ${(props) => props.marginRight || "10px"};
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Yellow07};
  }
`;

export const ButtonGreen = styled.button`
  background-color: ${Green};
  border: none;
  color: ${Black};
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: 10px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Green09};
  }
`;

export const ButtonOrange = styled.button`
  background-color: ${Orange};
  border: none;
  color: ${Black};
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: 10px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Orange09};
  }
`;

export const ButtonNeonGreen = styled.button`
  background-color: ${NeonGreen};
  border: none;
  color: ${Black};
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: 10px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${NeonGreen07};
  }
`;

export const ButtonPurple = styled.button`
  background-color: ${Purple};
  border: none;
  color: ${Black};
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: 10px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Purple09};
  }
`;

export const ButtonBlack = styled.button`
  background-color: ${Black};
  border: none;
  color: ${White};
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-right: 10px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Black};
    color: ${NeonGreen};
  }
`;

export const ButtonOutline = styled.button`
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  margin-right: 10px;
  background-color: transparent;
  border: none;
  color: ${(props) => props.color};
  color: ${(props) => props.textOverride};
  width: ${(props) => props.width};
  -webkit-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  -moz-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  &:hover {
    color: ${Black};
    color: ${(props) => props.textOverride};
    background: ${(props) => props.color};
  }
`;

export const ButtonOutlineBlack = styled.button`
  padding: 10px 24px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  margin-right: 10px;
  background-color: transparent;
  border: none;
  color: ${Black};
  width: ${(props) => props.width};
  -webkit-box-shadow: inset 0px 0px 0px 2px ${Black};
  -moz-box-shadow: inset 0px 0px 0px 2px ${Black};
  box-shadow: inset 0px 0px 0px 2px ${Black};
  &:hover {
    color: ${White};
    background: ${Black};
  }
`;
