import * as React from "react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";

import { Black, Gray, LightGray, White } from "../theme/colors";
import { H5, H6, TextXs } from "../theme/text/Headings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const IllustrationImg = styled.img`
  width: 200px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  border-radius: 15px 15px 0 0;
`;

const ImagePlaceholder = styled.div`
  background-color: ${Black};
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: 200px;
  background-position: center;
  z-index: 1;
  height: 200px;
  width: 100%;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;

  &:hover {
    background-size: 210px;
    transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s;
  }
`;

const Box = styled.div`
  color: ${Black};
  padding: 25px;
  width: 100%;
  background-color: ${White};
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  min-height: 100px;
  text-align: left;
`;

const BoxLink = styled.a`
  text-decoration: none;
`;

const BoxAHrefs = styled.a`
  text-decoration: none;
`;

const Overview = styled.div`
  margin-bottom: 50px;
  &:hover {
    cursor: pointer;

    ${IllustrationImg} {
      transform: scale(1.07);
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s;
    }
  }
`;

const Overlay = styled.div`
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 999;
  padding: 15px;
  background-color: ${(props) => props.upcoming && "rgba(0, 0, 0, 0.5)"};
  border-radius: 0 0 15px 15px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
`;

const CourseBox = (props) => {
  return (
    <BoxLink to={props.CourseLink}>
      <BoxAHrefs
        href={props.SignUpLink}
        rel="noopener noreferrer"
        target={"_blank"}
        aria-label="sign up link"
      >
        <Overview>
          <ImageContainer>
            <ImagePlaceholder backgroundImage={props.expertImg}>
              <Overlay upcoming={props.upcoming}>
                <Row>
                  <Col>
                    {props.upcoming ? (
                      <H6
                        color={LightGray}
                        className="mb-0 text-align-right ml-10"
                      >
                        Currently Building{" "}
                        <FontAwesomeIcon icon={faBell} className={"ml-5"} />
                      </H6>
                    ) : (
                      <H6
                        color={LightGray}
                        className="mb-0 text-align-right ml-10"
                      >
                        Check It Out!{" "}
                      </H6>
                    )}
                  </Col>
                </Row>
              </Overlay>
            </ImagePlaceholder>
          </ImageContainer>
          <Box>
            <H5 className="noDecoration mb-20">{props.title}</H5>
            <Row>
              <Col lg={{ size: "8" }}>
                <H6 color={LightGray}>About</H6>
                <TextXs color={Gray}>{props.about}</TextXs>
              </Col>
              <Col lg={{ size: "4" }}>
                <H6 color={LightGray}>Price</H6>
                <TextXs color={Gray}>{props.price}</TextXs>
              </Col>
            </Row>
          </Box>
        </Overview>
      </BoxAHrefs>
    </BoxLink>
  );
};

export default CourseBox;
