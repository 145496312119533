import styled, { css } from "styled-components";
import {
  Green,
  NeonGreen,
  Orange,
  Yellow,
  Purple,
  Gray,
  White,
  Black,
  LightGray,
  Gray2,
} from "../colors";

export const D1 = styled.h1`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 55px;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.Black && Black};
`;

export const D2 = styled.h1`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  font-size: 42px;
  text-transform: uppercase;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.LightGray && LightGray};
`;

export const H1 = styled.h1`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.Black && Black};
`;

export const H2 = styled.h2`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.Black && Black};
`;

export const H3 = styled.h3`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.LightGray && LightGray};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.Black && Black};
`;

export const H4 = styled.h4`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.Black && Black};
`;

export const H5 = styled.h5`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 400;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.Black && Black};
`;

export const H6 = styled.h6`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.Black && Black};
`;

export const TextXs = styled.h6`
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.lightGray && LightGray};
`;

export const TextSm = styled.h6`
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.lightGray && LightGray};
`;

export const Paragraph = styled.p`
  font-family: "Helvetica Neue";
  color: ${Black};
  opacity: 0.9;
  ${(props) =>
    props.footer &&
    css`
      margin-bottom: 5px;
    `}
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.LightGray && LightGray};
`;

export const BlogTitle = styled.h1`
  font-family: Fira Sans Extra Condensed, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${Black};
  text-decoration: none;
  font-display: swap;

  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Black && Black};
`;

export const BlogSubTitle = styled.h2`
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Gray2};
  text-decoration: none;
  font-display: swap;

  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Black && Black};
`;

export const BlogDate = styled.h3`
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${Gray2};
  position: absolute;
  bottom: 0;
  text-decoration: none;
  font-display: swap;

  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Black && Black};
`;

export const Span = styled.span`
  font-display: swap;

  color: ${(props) => props.NeonGreen && NeonGreen};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.Orange && Orange};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Green && Green};
  color: ${(props) => props.Yellow && Yellow};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.LightGray && LightGray};
  font-weight: ${(props) => props.Bold && "400"};
`;
